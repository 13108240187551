.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(primary, 3);
}

.features-tiles-item-image2 {
	display: inline-flex;
	border-radius: 50%;
	background-color: #fff;
}
